a,
a:hover,
a:focus {
  /* color: inherit;*/
  text-decoration: none;
  transition: all 0.3s;
}

.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}
.alert.alert-shadowed {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.alert.radius-bordered {
  border-radius: 5px;
  background-clip: padding-box;
}

.alert {
  margin-bottom: 20px;
  margin-top: 0;
  border-width: 0;
  border-left-width: 5px;
  padding: 10px;
  border-radius: 0;
}
.has-shadow {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1), -1px 0 2px rgba(0, 0, 0, 0.05);
}
.border-5 {
  border-width: 5px !important;
}
.stats .row {
  margin: 0;
  padding: 15px 0;
  margin-bottom: 10px;
  min-height: 10px;
}
.progress-bar {
  background-color: var(--purpleTho);
}
.flex-stack {
  justify-content: space-between;
  align-items: center;
}
.row {
  display: flex;
  flex-wrap: wrap;
}
.card .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  min-height: 60px;
  font-size: 2rem;
  padding: 0 2rem 0.5rem 2rem;
  background-color: transparent;
  border-bottom: 1px dashed #e8e5dd;
}
.card .card-body {
  padding: 1rem 0.5rem 0 0.5rem;
}
.card {
  padding: 0.5rem 0;
}

.form-control {
  border: rgba(0, 0, 0, 0);
}
.form-control:focus {
  box-shadow: 0 0 0.2rem rgb(68, 68, 68);
}
.btn:not(.btn-custom-padding) {
  padding: 0.5rem !important;
}
.text-orange-500 {
  color: gold;
}
.border-orange-500 {
  border-color: gold !important;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.wrapper {
  display: flex;
  width: 100%;
}

#sidebar {
  width: 280px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 999;
  transition: all 0.3s;
  background-color: black;
  min-height: 680px;
  /* min-height: calc(); */
}
#sidebar hr {
  margin: 0.5rem 0;
  color: darkgrey;
  background-color: currentColor;
  border: 0;
  opacity: 0.5;
  height: 0.2rem;
}
#sidebar .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-image: linear-gradient(
    90deg,
    rgb(255, 166, 0) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background-color: gold;
}
#sidebar .nav-link {
  color: white;
}
#sidebar .sidebar-header {
  display: flex;
  margin: 1rem 0;
}
#sidebar .sidebar-header span {
  display: none;
}
#sidebar .sidebar-footer {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  height:150px;
  margin-left: auto;
  margin-right: auto;
}
#sidebar .nav-link:hover {
  background-color: rgba(160, 160, 160, 0.4);
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content {
  width: calc(100% - 280px);
  padding: 20px;
  padding-bottom: 3rem;
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(150, 150, 150, 0.2);
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  #sidebar {
    min-width: 60px;
    max-width: 60px;
    text-align: center;
    margin-left: 0px !important;
    min-height: 110vh;
  }
  #sidebar .nav-item span {
    display: none;
  }
  #sidebar .sidebar-header img {
    display: none;
  }
  #sidebar .sidebar-header span {
    display: block;
  }
  #sidebar .sidebar-footer .balance,
  #sidebar .sidebar-footer span {
    display: none;
    height:50px;
  }
  #sidebar .nav-link {
    padding: 1px 0 1px 0 ;
  }
  #sidebar hr {
    margin: 2px;
  }
  #content {
    width: calc(100% - 60px);
    padding: 5px;
  }
  .footer .address {
    display: none;
  }

  .card-body {
    padding: 0.1rem !important;
  }
  .card ul {
    padding: 0 0 0 1.5rem;
  }
}
