/*! `Custom` Bootstrap 5 theme */
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,700);
$headings-font-family:Source Sans Pro;

$primary:gold;
$secondary:#303030;
$light:#e8e8e8;
$dark:#000000;
$enable-gradients:true;
$spacer:0.7rem;
// $border-width:1px;
// $btn-border-radius:.25rem;
$btn-white-space: nowrap;

// $body-bg:rgba(150, 150, 150, 0.2);
// $body-color:rgb(50, 50, 50);

@import "bootstrap";

// Add SASS theme customizations here..